export default class Element {
  constructor(parentNode, attributes = {}, tag = 'div') {
    this.parentNode = parentNode
    this.attributes = attributes
    this.tag = tag
  }

  createElement() {
    const element = document.createElement(this.tag)

    for (let key in this.attributes) {
      element.setAttribute(key, this.attributes[key])
    }

    return element
  }

  insertElement() {
    const newElement = this.createElement()
    this.parentNode.appendChild(newElement)
  }
}

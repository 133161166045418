import Element from './element'

export default class Indicators {
  constructor(carousel) {
    this.carousel = carousel
  }

  render() {
    const trackContainer = this.carousel.querySelector('.glide__track')

    if (trackContainer) {
      const trackAttributes = {
        class: 'indicators glide__bullets',
        'data-glide-el': 'controls[nav]'
      }

      new Element(trackContainer, trackAttributes).insertElement()
    }

    const indicatorsContainer = this.carousel.querySelector('.indicators')

    if (indicatorsContainer) {
      const slides = this.carousel.querySelectorAll('.glide__slide')

      slides.forEach((_slide, idx) => {
        const indicatorAttributes = {
          class: 'btn indicator glide__bullet',
          'data-glide-dir': '=' + idx,
          'aria-label': 'Slide ' + idx
        }

        new Element(indicatorsContainer, indicatorAttributes, 'button').insertElement()
      })
    }
  }
}
